import React from 'react'

function Servicedetail() {
  return (
    <div style={{backgroundColor:"#021011",padding:'8% 3% 8% 3%'}}>
    <div className='container' style={{backgroundColor:"white",padding:'8% 3% 8% 3%'}}>
        <div className='row'>
            <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12'>
              <h3>Secure Premises with Site Security Guards</h3>
                <p> <span style={{ fontWeight:"bold"}}> Sharp Edge Security</span> offers a comprehensive range of security services designed to meet the unique needs of our clients. Our services include Event Security, where our trained personnel ensure the safety and security of attendees, staff, and assets at concerts, festivals, and corporate events. We also provide Corporate Security, securing office buildings and retail spaces with our proactive security.</p>
                <p>Our specialized services include Executive Protection, where our experienced agents provide discreet and personalized security for high-net-worth individuals, celebrities, and business leaders. We also offer CCTV Installation and Monitoring, providing real-time surveillance and rapid response to potential security breaches. Additionally, our Security Consulting services help businesses identify.</p>
                
            </div>
            <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12'>
              <h3>EVENT & FESTIVAL SECURITY​</h3>
                <p> <span style={{ fontWeight:"bold"}}> Sharp Edge Security,</span>our Event & Festival Security service is designed to provide a safe and secure environment for attendees, staff, and performers at concerts, festivals, and other large gatherings. Our highly trained and experienced security personnel are deployed to ensure the smooth execution of events, handling tasks such as access control, crowd management, and emergency response.</p>
                <p>Our event security teams are equipped to handle any situation, from minor incidents to major emergencies. We work closely with event organizers, venue management, and law enforcement agencies to ensure a coordinated and effective security strategy. Our personnel are trained to be highly visible, yet discreet, allowing attendees to focus on enjoying the event while we handle the security details.</p>                
            </div>
            <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12'>
              <h3>K-9 Unit Security​</h3>
                <p> <span style={{ fontWeight:"bold"}}> Sharp Edge Security's</span> K-9 Unit Security service offers an unparalleled level of detection and deterrence for our clients. Our expertly trained K-9 teams, comprising highly skilled handlers and their loyal canine partners, are deployed to detect and prevent potential security threats. Our K-9 units are trained to detect narcotics, explosives, and other harmful substances, ensuring a secure environment for our clients.</p>
                <p>Our K-9 teams are fully certified and compliant with industry standards, ensuring the highest level of proficiency and reliability. They are deployed in various settings, including event security, corporate security, and specialized security operations. Our handlers and canines work seamlessly together, providing a visible and effective security presence that deters potential threats and ensures a safe and secure environment.</p>                
            </div>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12'>
              <h3>Keyholding & Mobile Security​</h3>
                <p> <span style={{ fontWeight:"bold"}}> Sharp Edge Security's</span> Mobile Patrols Security service provides a visible and proactive security presence for our clients. Our highly trained and experienced security personnel patrol designated areas in marked vehicles, providing a deterrent to potential criminals and a rapid response to any security breaches. Our mobile patrols are equipped with state-of-the-art communication and navigation technology, ensuring efficient and effective coverage of our clients' properties.</p>
                <p>Our mobile patrols are tailored to meet the specific needs of our clients, including frequency and duration of patrols, response times, and reporting requirements. Our security personnel are trained to identify potential security risks and take proactive measures to prevent incidents from occurring. In the event of an incident, our mobile patrols are equipped to respond quickly and effectively, ensuring the safety of our clients' assets and personnel.</p>                
            </div>
            <div className='servicesdetail col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12'>
              <h3>Construction Site Security​</h3>
                <p> <span style={{ fontWeight:"bold"}}> Sharp Edge Security's</span>  Construction Site Security service provides comprehensive protection for building sites, equipment, and materials. Our experienced security personnel are deployed to prevent theft, vandalism, and trespassing, ensuring that our clients' assets are secure and their projects remain on schedule. Our construction site security teams are trained to monitor CCTV systems, patrol the site on foot or in vehicles, and respond quickly to any security breaches.</p>
                <p>Our construction site security services include access control, gate management, and surveillance monitoring. We work closely with our clients to develop a customized security plan that meets their specific needs and ensures compliance with industry regulations. Our security personnel are equipped with the latest technology, including body-worn cameras and mobile surveillance units, to ensure effective monitoring and response.</p>                
            </div>
            <div className='servicesdetail col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12'>
              <h3>Door Supervisor​</h3>
                <p> <span style={{ fontWeight:"bold"}}> Sharp Edge Security's</span>  Door Supervisor Security Service provides a highly visible and effective security presence at client premises, ensuring the safety and security of staff, customers, and assets. Our trained and licensed door supervisors are deployed to manage access control, monitor CCTV systems, and respond to security breaches. They are also trained in conflict resolution and first aid, ensuring a comprehensive security solution..</p>
                <p>
                Our door supervisors are equipped with the latest technology, including body-worn cameras and mobile surveillance units, to ensure effective monitoring and response. They are also trained to work closely with clients, understanding their specific security needs and tailoring their services to meet those needs. Whether it's a retail store, office building, or entertainment venue, our door supervisors are dedicated to providing a secure and welcoming environment.</p>                
            </div>
            <div className='servicesdetail col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12'>
              <h3>Cctv monitring​</h3>
                <p> <span style={{ fontWeight:"bold"}}> Sharp Edge Security's</span>   CCTV Monitoring Service provides real-time monitoring and surveillance of client premises, ensuring the safety and security of assets, staff, and customers. Our state-of-the-art monitoring center is staffed by highly trained and experienced security professionals who continuously monitor CCTV feeds, respond to alerts and alarms, and take proactive measures to prevent security breaches.</p>
                <p>At Sharp Edge Security, we understand the importance of effective CCTV monitoring in preventing and investigating security incidents. Our CCTV Monitoring Service is designed to provide our clients with an additional layer of security and peace of mind, knowing that their premises are being constantly monitored and protected. With our advanced technology and expertise, our clients can trust that their security needs are being met with the highest level of professionalism and dedication.</p>                
            </div>
        </div>
    </div>
</div>
  )
}

export default Servicedetail