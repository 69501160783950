import React, { useState } from 'react'
import './Navbar.css';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <header>
      <nav className="navbar">
        <div className="navbar-brand" href="#"><img style={{height:"70px",borderRadius:"100%",marginLeft:"30px"}} src="./images/sharp edge logo.jpg" alt=""></img></div>
        <div className="nav-links">
          <ul>
            <li ><Link className="navicon" to="/" >Home</Link></li>
            <li><Link className="navicon" to="about" >About</Link></li>
            <li><Link className="navicon" to="services" >Services</Link></li>
            <li><Link  className="navicon" to="contactus" >Contact</Link></li>
            <li  className="navavailable" >Available 24/7</li>
           
          </ul>
        </div>
        <div className={`toggle-button ${isOpen ? 'active' : ''}`} onClick={handleToggle}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
      </nav>
      <div className={`sidebar ${isOpen ? 'open' : ''}`}>
        <div className="sidebar-content">
          <ul>
            <li  onClick={closeMenu}><Link to="/">Home</Link></li>
            <li  onClick={closeMenu}><Link to="/about">About</Link></li>
            <li  onClick={closeMenu}><Link to="services">Services</Link></li>
            <li  onClick={closeMenu}><Link to="contactus">Contact</Link></li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
