import React from "react";
import "./Header.css";
function Header() {
  return (
    <div className="headerdiv">
      <p className="headerpara">Safeguard your Assests</p>
      <p className="headerparatwo" style={{ maxWidth: "900px" }}>
        Ensuring your peace of mind with our reliable, responsive, and
        results-driven security services, 24/7
      </p>
      <button className="btn-available">Available 24/7 </button>
      <img className="headerimg" src="./images/headerimg.jpg"></img>
    </div>
  );
}

export default Header;
