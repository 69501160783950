import React from 'react'
import "./aboutdetail.css"
function Aboutdetail() {
  return (
    <div style={{backgroundColor:"#021011",padding:'8% 3% 8% 3%'}}>
        <div className='container' style={{backgroundColor:"white",padding:'8% 3% 8% 3%'}}>
            <div className='row'>
                <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                    <p > <span style={{ fontWeight:"bold"}}> Sharp Edge Security</span> is a UK-based security services company dedicated to providing top-notch protection for our clients' assets, people, and premises. Our team of highly trained and experienced security professionals is committed to delivering exceptional service, ensuring our clients' peace of mind and safeguarding their interests.</p>
                    <p>With a strong focus on expertise, reliability, and customer satisfaction, Sharp Edge Security offers a comprehensive range of services, including event security, corporate security, and specialized security solutions. Our mission is to build long-lasting relationships with our clients, providing them with tailored security solutions that meet their unique needs and exceed their expectations.</p>
                </div>
                <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12'><img className='aboutimg' style={{width:"100%",backgroundPosition:"center",backgroundSize:"cover",objectFit:"cover"}} src="./images/aboutimg.jpg"></img></div>
            </div>
        </div>
    </div>
  )
}

export default Aboutdetail