import React from 'react'
import "./Contactheader.css"
function Contactheader() {
  return (
    <div className="contactmaindiv">
    <img className="contactimgdiv" src="./images/Contactheader.jpg" alt="" />
    <span className="contactus"><b> Contact Us</b></span>
  </div>
  )
}

export default Contactheader