import React from "react";
import "./Bodypara.css";
function Bodypara() {
  return (
    <div
      style={{ backgroundColor: "#021011", padding: "100px 10px 100px 10px" }}
    >
      <div className="container ">
        <div className="row g-3">
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 mt-3 ">
            <div style={{ border: "2px solid whitesmoke" }}>
              <p className="headingone">Experienced and Trained Guards</p>{" "}
              <p className="bodyparaone">
                Our security guards are highly experienced and trained
                professionals who are equipped to handle any security situation.
                With their expertise, you can have peace of mind knowing that
                your business and events are in safe.
              </p>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 mt-3 ">
            <div style={{ border: "2px solid whitesmoke" }}>
              <p className="headingone"> Customized Security Solutions</p>
              <p className="bodyparaone">
              We understand that every business and event has unique security
needs. That’s why we offer customized security solutions
tailored to your specific requirements. Our team will work
closely with you to assess your security.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Bodypara;

