import React from 'react'
import "./FeatureKeys.css"

function FeatureKeys() {
  return (
    <div style={{backgroundColor:"#021011",padding:"100px 10px 100px 10px"}}>
      <h1 style={{color:"white",textAlign:"center"}}> Key Points</h1>
    <div className='container mt-4'>
      <div className='row g-3'>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 mt-3 '>
        <div className='p-2'  style={{border:"1px solid whitesmoke"}} ><p className='featureone' ><i  style={{fontSize:"17px"}} class="fa fa-diamond" aria-hidden="true"></i> Highly Trained Security Personnel</p> <p className='paraone'>Constant surveillance to ensure the safety of your premises</p></div>
        </div>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 mt-3'>
        <div className='p-2' style={{border:"1px solid whitesmoke"}} ><p className='featureone' ><i style={{fontSize:"17px"}} class="fa fa-diamond" aria-hidden="true"></i> Customized Security Solutions</p> <p className='paraone'>Experienced and skilled guards to handle any security situation</p></div>
        </div>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 mt-3'>
        <div className='p-2' style={{border:"1px solid whitesmoke"}} ><p className='featureone' ><i style={{fontSize:"17px"}} class="fa fa-diamond" aria-hidden="true"></i> 24/7 Security Monitoring</p> <p className='paraone'>Tailored security plans to meet your specific needs and requirements</p></div>
        </div>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 mt-3'>
        <div  className='p-2' style={{border:"1px solid whitesmoke"}} ><p className='featureone' ><i style={{fontSize:"17px"}} class="fa fa-diamond" aria-hidden="true"></i> Quick Response Time</p> <p className='paraone'>Immediate action taken in case of any security breach or emergency </p></div></div>
        </div>
      </div>
      </div>
 
 
  
  )
}

export default FeatureKeys

























