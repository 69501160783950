import React from 'react'
import "./Servicesheader.css"
function Servicesheader() {
  return (
<div className="servicesmaindiv">
        <img className="servicesimgdiv" src="./images/Servicesheader.jpg" alt="" />
        <span className="services"><b> Services</b></span>
      </div>
  )
}

export default Servicesheader