import React from 'react'
import "./marquee.css"
function Marquee() {
  return (
    <div class="marquee-container">
        <div class="marquee-content">
            <span class="text-item">24/7 control room</span>
            <span class="text-item">Cctv monitoring</span>
            <span class="text-item">Door Supervisor</span>
            <span class="text-item">Retail Security</span>
            <span class="text-item">Mobile & Keyholding</span>
            <span class="text-item">Call Us: +44 7438 729715</span>
        </div>
    </div>
  )
}

export default Marquee