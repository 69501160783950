import React from 'react'
import "./Bodydivs.css"
function Bodydivs() {
  return (
    <div style={{backgroundColor:"#021011"}}>
      <p style={{textAlign:"center",fontWeight:"bold",color:" whitesmoke",fontSize:"35px"}}>Our Security Services </p>
      <div className='container mt-5' >
        <div className='row'>
        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 mt-4'><p className='bodydivone'>Door Supervisor</p><img   className='bodydivimgtwo' src="./images/doorsupervisor.jpg"/> </div>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 mt-4' > <p className='bodydivone'>K9-Unit Security</p><img className='bodydivimgone'   src="./images/K9.jpg"/> </div>
           < div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 mt-4'><p className='bodydivone'>Construction-Site security</p><img className='bodydivimgthree'   src="./images/construction.jpg"/> </div>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 mt-4'><p className='bodydivone'>Events & Fesival Security</p><img className='bodydivimgfour'   src="./images/Event security.jpg"/> </div>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 mt-4'><p className='bodydivone'>Mobile Security</p><img className='bodydivimgfour'   src="./images/Mobile.jpg"/> </div>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 mt-4'><p className='bodydivone'>Cctv Monitoring</p><img className='bodydivimgfour'   src="./images/cctv monitring.jpg"/> </div>
        </div>
      </div>
      </div>
  )
}

export default Bodydivs