import React from 'react'

import "./Aboutheader.css"
function Aboutheader() {
  return (
    <div className="maindiv">
        <img className="imgdiv" src="./images/Aboutheaderr.jpg" alt="" />
        <span className="aboutus"><b> About Us</b></span>
      </div>
  )
}

export default Aboutheader