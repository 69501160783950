
import { Link } from "react-router-dom"
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import "./Footer.css"
import React from 'react'

function Footer() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (

  
    <div>
         <div className="container-fluid" id="footermaincontainer" >
    <div className="row">
      <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 " >
        <div style={{display:"flex"}}><p ><img style={{height:"100px",borderRadius:"60%"}} src="./images/sharp edge logo.jpg"/></p> <ul style={{listStyleType:"none",margin:"20px 0 0 -20px"}}>  <li style={{color:"whitesmoke",fontWeight:"bold"}}> Save & Secure Your Assets</li>
      <li style={{color:"whitesmoke",fontWeight:"bold",margin:"20px 0 0px 0"}}>SHARP EDGE SECURITY LTD.</li></ul></div>
      <hr style= {{width: "80%", marginBottom:"6%", color: "white"}}/>
      <b>
      <p style={{color:"whitesmoke"}}>Committed to Excellence in Security Services</p>
      <p style={{color:"whitesmoke"}}>Customer Satisfaction Guaranteed</p></b>
    </div>
      
      <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12" ><p style={{fontSize:"18px",color:"white",paddingTop:"1.5%"}}><b>Contact Detail</b></p>
        <hr style={{color:"white"}}/>
        <li id="getlooking" style={{marginTop: "10%", fontSize:"13px",listStyleType: "none", color: "white"}}><b>118a Graham Road, Southampton, Englad,SO14 OAZ</b> </li> 
        <li id="getlooking" style={{marginTop: "8%", fontSize:"13px",listStyleType: "none", color: "white"}}><b> Contact #  :  +44 7438 729715</b>
</li>
<li id="getlooking" style={{marginTop: "8%", fontSize:"13px",listStyleType: "none", color: "white"}}><b> 
Email : info@sharpedgesecurity.co.uk</b> 
</li>
<li id="getlooking" style={{marginTop: "8%", fontSize:"13px",listStyleType: "none", color: "white"}}><b>
Mon to Fri : 9:00 AM – 5:00 PM</b></li>
      </div>
      <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12"><p style={{fontSize: "18px", color: "white",paddingTop: "1.5%"}}><b>For More Detail</b></p>
        <hr style={{color:"whitesmoke"}}/>
        <Link  to="/" id="getlooking" style={{ fontSize:"13px",listStyleType: "none", color: "white",textDecoration:"none"}}><b> Home</b> </Link>  <br/><br/>
        <Link  to="/about" id="getlooking" style={{ fontSize:"13px",listStyleType: "none", color: "white",textDecoration:"none"}}><b>About</b> </Link> <br/><br/>
        <Link  to="/services" id="getlooking" style={{ fontSize:"13px",listStyleType: "none", color: "white",textDecoration:"none"}}><b>Services</b> </Link> <br/><br/>
        <Link  to="/contactus" id="getlooking" style={{ fontSize:"13px",listStyleType: "none", color: "white",textDecoration:"none"}}><b>Contact</b> </Link><br/><br/> </div>
    </div>
   </div>

<div style={{textAlign:"center",backgroundColor:"#021011",padding:"2%"}}><a href=""> <i className="fa fa-facebook" style={{fontSize:"20px",color:"whitesmoke"}} aria-hidden="true"></i></a>
<a href=""> <i className="fa fa-instagram" style={{fontSize:"20px",color:"whitesmoke",marginLeft:"4.5%"}}aria-hidden="true"></i></a> </div>
    </div>
  )
}

export default Footer