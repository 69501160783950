import React, { useRef, useState } from "react";
import emailjs from "emailjs-com";
import "./Contactusdetail.css";

function ContactUsdetail() {
  const form = useRef();
  const [submitted, setSubmitted] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_i87o9gh",
        "template_fg7g2uv",
        form.current,
        "V_gbnDxS68o4ACHO_"
      )
      .then(
        (result) => {
          console.log(result.text);
          setSubmitted(true); // Set form as submitted
        },
        (error) => {
          console.log(error.text);
          alert("Failed to send message. Please try again.");
        }
      );
  };

  return (
    <div style={{ backgroundColor: "#021011", padding: "10% 20% 10% 20%" }}>
      <div
        style={{
          textAlign: "center",
          alignContent: "center",
          backgroundColor: "white",
          padding: "5% 5% 5% 5%",
          borderRadius: "3px",
        }}
      >
        {submitted ? (
          <h1 className="thank-you">Thank you for submitting your message! <i style={{color : "green" , fontSize : "30px"}} class="fa fa-check" aria-hidden="true"></i></h1>
        ) : (
          <form ref={form} onSubmit={sendEmail}>
            <h1 className="heading">Send Us Detail</h1>
            <div className="input mt-3">
              <input
                style={{ padding: "1%", outline: "none",maxWidth:"175px" }}
                placeholder="First Name"
                name="first_name"
                required
                className="firstname"
              />
            </div>
            <div className="input mt-3">
              <input
                style={{ padding: "1%", outline: "none",maxWidth:"175px" }}
                placeholder="Last Name"
                name="last_name"
                required
              />
            </div>
            <div className="input mt-3">
              <input
                style={{ padding: "1%", outline: "none",maxWidth:"175px" }}
                placeholder="Phone #"
                name="phone"
                required
              />
            </div>
            <div className="input mt-3">
              <input
                style={{ padding: "1%", outline: "none",maxWidth:"175px" }}
                placeholder="E.mail"
                name="email"
                required
              />
            </div>
            <div className="input mt-3">
              <textarea
                style={{ padding: "5% 2.7% 1.7% 1%", outline: "none" }}
                placeholder="Detail"
                name="message"
                required
              />
            </div>
            <button
              className="button"
              style={{
                cursor: "pointer",
                marginTop: "5%",
                backgroundColor: "#021011",
                padding: "5px 10px 5px 10px",
                color: "white",
                border: "none",
              }}
            >
              Submit
            </button>
          </form>
        )}
      </div>
    </div>
  );
}

export default ContactUsdetail;
