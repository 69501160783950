import React from 'react'


function Questions() {
  return (
    <div style={{backgroundColor:"#131717",padding:"10% 4% 10% 4%"}}>
        <div className='container'>
            <div className='row'>
                <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12'><p style={{color:'whitesmoke'}}>FAQ</p><p style={{color:'whitesmoke',fontWeight:"Bold"}}>Common questions</p><p style={{color:'whitesmoke'}}>Here are some of the most common Questions that we get :</p></div>
                <div className='col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-12'><p style={{color:"whitesmoke",fontWeight:"bold"}}>What services do you provide?</p> <p style={{color:"whitesmoke"}}>We provide security guard services for various industries including residential, commercial, retail, and events.</p>
                <p style={{color:"whitesmoke",fontWeight:"bold"}}>Are your security guards licensed?</p><p style={{color:"whitesmoke"}}>Yes, all our security guards are licensed and trained to meet the industry standards.</p> <p style={{color:"whitesmoke",fontWeight:"bold"}}>How do you ensure the quality of your security guards?</p><p style={{color:"whitesmoke"}}>We have a rigorous selection process that includes background checks, interviews, and training to ensure we hire only the most qualified and reliable security guards.</p> <p style={{color:"whitesmoke",fontWeight:"bold"}}>Can you provide security guards on short notice?</p>
                <p style={{color:"whitesmoke"}}>Yes, we understand that emergencies can arise, and we have a team of security guards ready to be deployed at short notice.</p>
                <p style={{color:"whitesmoke",fontWeight:"bold"}}>What areas do you cover in the UK?</p>
                <p style={{color:"whitesmoke"}}>We provide security guard services across the entire UK, including major cities and remote locations.</p></div>
            </div>
        </div>
    </div>
  )
}

export default Questions