import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./components/navbar";
import Header from "./components/Header";
import Bodypara from "./components/Bodypara";
import Bodydivs from "./components/Bodydivs";
import FeatureKeys from "./FeatureKeys";
import Questions from "./Questions";
import Marquee from "./components/Marquee";
import Footer from "./components/Footer";
import Aboutdetail from "./components/Aboutdetail";
import Servicedetail from "./components/Servicedetail";
import Aboutheader from "./components/Aboutheader";
import ContactUsdetail from "./components/ContactUsdetail";
import Servicesheader from "./components/Servicesheader";
import Contactheader from "./components/Contactheader";

function App() {
  return (
    <div>
      <BrowserRouter>
      <Navbar />
      <Routes>
<Route path="/" element={<Header/>}></Route>
    </Routes>
    <Routes>
<Route path="/" element={<Bodypara/>}></Route>
    </Routes>
    <Routes>
<Route path="/" element={<Bodydivs/>}></Route>
    </Routes>
     <Routes>
<Route path="/" element={<FeatureKeys/>}></Route>
    </Routes>
    <Routes>
<Route path="/" element={<Marquee/>}></Route>
    </Routes>
    <Routes>
<Route path="/" element={<Questions/>}></Route>
    </Routes>
    <Routes>
 <Route path="/about" element={<Aboutheader/>}></Route>
    </Routes>
    <Routes>
 <Route path="/about" element={<Aboutdetail/>}></Route>
    </Routes> 
    <Routes>
 <Route path="/about" element={<Marquee/>}></Route>
    </Routes> 
    <Routes>
 <Route path="/services" element={<Servicesheader/>}></Route>
    </Routes> 
    <Routes>
  <Route path="/services" element={<Servicedetail/>}></Route>
    </Routes> 
    <Routes>
 <Route path="/services" element={<Marquee/>}></Route>
    </Routes> 
    <Routes>
 <Route path="/contactus" element={<Contactheader/>}></Route>
    </Routes> 
    <Routes>
 <Route path="/contactus" element={<ContactUsdetail/>}></Route>
    </Routes> 
    <Routes>
 <Route path="/contactus" element={<Marquee/>}></Route>
    </Routes> 
      <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
